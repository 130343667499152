<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="body-1 font-weight-medium">3 Konten Impression Tertinggi</span>
    </v-card-title>

    <v-card-text>
      <v-list class="pb-0">
        <v-list-item
          v-for="(list,index) in data"
          :key="list.id"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-4':''}`"
        >
          <v-avatar
          v-if="isLoading"
            color="primary"
            size="40"
            class="primary white--text font-weight-medium me-3"
          >
            <span class="text-base"></span>
          </v-avatar>
          <v-avatar v-else class="me-3" size="40">
              <v-img :src="list.photo" :alt="list.photo" height="80px"></v-img>
          </v-avatar>

          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-semibold">
                <span class="text--primary me-1 text-capitalize">{{ list.type === 'custom' ? list.packages.format : list.packages.content }}</span>

                <!-- <v-icon
                  size="20"
                  :color="data.change.charAt(0) === '+' ? 'success':'error'"
                >
                  {{ data.change.charAt(0) === '+' ? icons.mdiChevronUp: icons.mdiChevronDown }}
                </v-icon> -->

                <!-- <span :class="`text-xs ${data.change.charAt(0) === '+' ? 'success--text':'error--text'}`">{{ data.change.slice(1) }}</span> -->
              </div>

              <v-list-item-subtitle class="text-xs text-capitalize">
                {{ list.name }}
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
              <h4 class="font-weight-semibold">
                {{ list.impressions | numberFormat }}
              </h4>
              <span class="text-xs">Impressions</span>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
      isLoading:true
    }
  },
  mounted(){
    this.loadImage()
  },
  methods:{
    loadImage(){
      let data = this.data
      this.isLoading = true;
      let token = localStorage.getItem('token')
      for(let a=0; a < data.length; a++){
        axios({
          url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
          headers: {
            'Authorization': 'Bearer '+ token
          },
          method: 'GET',
          responseType: 'blob',
        }).then((res) => {
          const blob = new Blob([res.data], { type: "application/JPG" });
          data[a].photo = URL.createObjectURL(blob);
        });
      }
      this.data = data
      setTimeout(() => { 
        this.isLoading = false
      }, 500);
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
