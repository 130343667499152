<template>
  <!-- <v-card class="elevation-0">
    <v-card-title class="font-weight-medium body-1">3 Campaign Terakhir</v-card-title>
    <v-data-table
      :headers="headers"
      :items="data"
      item-key="name"
      class="elevation-1"
      hide-default-footer
    >
    </v-data-table>
  </v-card> -->
  <div v-if="isLoading">
    <!-- DESKTOP -->
    <div v-if="!isMobile()">
      <div class="d-flex justify-center">
        <v-skeleton-loader
          class="mx-auto pa-1"
          width="1200"
          :height="height"
          type="card"
        ></v-skeleton-loader>
      </div>
    </div>
    <!-- MOBILE -->
    <div v-else class="">
      <div class="d-flex justify-center">
        <v-skeleton-loader
          class="mx-auto round-16"
          width="1200"
          height="200"
          type="card"
        ></v-skeleton-loader>
      </div>
    </div>
  </div>
  <div v-else>
    <v-card color="primary" class="round-16" style="width: 1000px;">
      <v-carousel
        hide-delimiters
        cycle
        :height="height"
        width="1000"
        >
        <v-carousel-item
          class="cursor-pointer"
          v-for="(item,i) in items"
          :key="i"
          :src="item.source"
          @click="openApp(item.link)"
        ></v-carousel-item>
        <!-- <v-carousel-item
          :src="require('@/assets/images/dashboard/trends.png')"
        ></v-carousel-item> -->
      </v-carousel>
      <!-- <v-img
        :height="height"
        :src="require('@/assets/images/dashboard/trends.png')"
      ></v-img> -->
      <!-- <v-card-title class="font-weight-medium body-1">3 Campaign Terakhir</v-card-title> -->
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  // props:['data'],
  setup() {
    return {
      isLoading:true,
      height:300,
      items:[],
      type: 'platform'
    }
  },
  mounted() {
    if(this.isMobile()){
      this.height=200
    } else {
      this.height=300
    }
    this.loadBanners()
  },
  computed: {
    headers() {
      return [
        {text: 'No.', value: 'num', sortable: false},
        {text: 'Invoice', value: 'invoice', sortable: false},
        {text: 'Tanggal', value: 'date_added', sortable: false},
      ]
    },
  },
  methods: {
    loadBanners(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'banners/getBanners/'+this.type, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            let data = response.data.data
            this.items = data
            setTimeout(() => {
              this.isLoading = false
            }, 200);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    dateFormat(value){
      return value.toString().slice(0, 10)
    },
    openApp(link){
      // window.location.href = link
      window.open(link,'_blank')
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
