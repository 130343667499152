<template>
  <div>
    <!-- DESKTOP -->
    <div class="full-width" v-if="!isMobile()">
      <v-row class="mt-0">
        <div class="col-md-12 col-sm-12 col-lg-12 pt-0">
          <v-card class="overflow-hidden elevation-1 round-16">
            <v-card-title class="text-20 font-weight-bold d-flex">
              <div>
                Insights
              </div>
              <!-- <v-btn
                class="ml-auto text-capitalize"
                small
                rounded
                outlined
              >Last 30 days</v-btn> -->
              <div class="ml-auto mb-1" style="width:140px">
                <v-select
                  v-model="filterValue"
                  class="text-capitalize text-12"
                  :items="filter"
                  dense
                  outlined
                  hide-details
                  @change="filterDay()"
                ></v-select>
              </div>
              <!-- <v-chip color="primary" class="ml-auto"><span class="text-12 black--text">Last 30 days</span></v-chip> -->
            </v-card-title>
            <v-card-actions class="d-flex justify-center subtitle-2">
              <div class="row">
                <div class="col-md-2 col-sm-4 col-6 border-right">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="orange"
                    >
                      <i class="far fa-user white--text text-16"></i>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">People Reached</p>
                  <h1>{{ data.reach | nFormatter}}</h1>
                  <p class="text-14 mt-4 mb-0">
                    <span class="font-weight-bold success--text">IDR {{ limitNumber(data.cpr) | nFormatter }}</span>
                    CPR
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <svg v-bind="attrs"
                          v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                      </template>
                      <span>Cost Per Reach</span>
                    </v-tooltip>
                  </p>
                </div>
                <div class="col-md-2 col-sm-4  col-6 border-right">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="success"
                    >
                      <i class="fas fa-history white--text text-16"></i>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Impressions</p>
                  <h1>{{ data.impressions | nFormatter}}</h1>
                  <p class="text-14 mt-4 mb-0">
                    <span class="font-weight-bold success--text">IDR {{ limitNumber(data.cpi) | nFormatter }}</span>
                    CPI
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <svg v-bind="attrs"
                          v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                      </template>
                      <span>Cost Per Impressions</span>
                    </v-tooltip>
                  </p>
                </div>
                <div class="col-md-2 col-sm-4  col-6 border-right">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="info"
                    >
                      <i class="far fa-eye white--text text-16"></i>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Views</p>
                  <h1>{{ data.views | nFormatter}}</h1>
                  <p class="text-14 mt-4 mb-0">
                    <span class="font-weight-bold success--text">IDR {{ limitNumber(data.cpv) | nFormatter }}</span>
                    CPV
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <svg v-bind="attrs"
                          v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                      </template>
                      <span>Cost Per Views</span>
                    </v-tooltip>
                  </p>
                </div>
                <div class="col-md-2 col-sm-4  col-6 border-right">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="secondary"
                    >
                      <i class="far fa-thumbs-up white--text text-16"></i>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Likes</p>
                  <h1>{{ data.likes | nFormatter}}</h1>
                  <p class="text-14 mt-4 mb-0">
                    <span class="font-weight-bold success--text">{{ limitNumber(data.comments) | nFormatter }}</span>
                    Comments
                  </p>
                </div>
                <div class="col-md-2 col-sm-4  col-6 border-right">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="error"
                    >
                      <svg class="white--text text-22" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M11.975 22H12c3.859 0 7-3.14 7-7V9c0-3.841-3.127-6.974-6.981-7h-.06C8.119 2.022 5 5.157 5 9v6c0 3.86 3.129 7 6.975 7zM7 9a5.007 5.007 0 0 1 4.985-5C14.75 4.006 17 6.249 17 9v6c0 2.757-2.243 5-5 5h-.025C9.186 20 7 17.804 7 15V9z"/><path fill="currentColor" d="M11 6h2v6h-2z"/></svg>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Clicks</p>
                  <h1>{{ data.clicks | nFormatter}}</h1>
                  <p class="text-14 mt-4 mb-0">
                    <span class="font-weight-bold success--text">{{ limitNumber(data.share) | nFormatter }}</span>
                    Shares
                  </p>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="accent"
                    >
                      <svg class="far fa-eye white--text text-20" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 0 0-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 0 1 5.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 0 1 9.288 0M15 7a3 3 0 1 1-6 0a3 3 0 0 1 6 0Zm6 3a2 2 0 1 1-4 0a2 2 0 0 1 4 0ZM7 10a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"/></svg>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Followers</p>
                  <h1>{{ data.followers | nFormatter}}</h1>
                  <p class="text-14 mt-4 mb-0">
                    <span class="font-weight-bold success--text">{{ limitNumber(data.account_engaged) | nFormatter }}</span>
                    AE
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <svg v-bind="attrs"
                          v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                      </template>
                      <span>Account Engaged</span>
                    </v-tooltip>
                  </p>
                </div>
                <!-- <div class="col-md-2 col-sm-4 col-6 border-right">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="success"
                    >
                      <i class="far fa-thumbs-up white--text text-16"></i>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Likes</p>
                  <h1>{{ data.likes | nFormatter}}</h1>
                </div>
                <div class="col-md-2 col-sm-4  col-6 border-right">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="info"
                    >
                      <i class="far fa-comments text-16 white--text"></i>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Comments</p>
                  <h1>{{ data.comments | nFormatter}}</h1>
                </div>
                <div class="col-md-2 col-sm-4  col-6 border-right">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="secondary"
                    >
                      <i class="far fa-eye white--text text-16"></i>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Views</p>
                  <h1>{{ data.views | nFormatter}}</h1>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                  <p>
                    <v-btn
                      class="mx-2 ml-0"
                      fab
                      small
                      color="accent"
                    >
                      <svg class="far fa-eye white--text text-20" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 0 0-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 0 1 5.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 0 1 9.288 0M15 7a3 3 0 1 1-6 0a3 3 0 0 1 6 0Zm6 3a2 2 0 1 1-4 0a2 2 0 0 1 4 0ZM7 10a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"/></svg>
                    </v-btn>
                  </p>
                  <p class="text-14 mt-4">Followers</p>
                  <h1>{{ data.followers | nFormatter}}</h1>
                </div> -->
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="full-width" v-else>
      <div class="d-flex">
        <p class="text-16 mb-0 mt-1 font-weight-bold black--text">Insights</p>
        <div class="ml-auto mb-3" style="width:130px">
          <v-select
            v-model="filterValue"
            class="text-capitalize text-10 black-matte"
            :items="filter"
            dense
            outlined
            hide-details
            @change="filterDay()"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 col-sm-4 col-6">
          <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
            <p class="mb-2">
              <v-btn
                class=""
                fab
                small
                color="orange"
              >
                <i class="far fa-user white--text text-16"></i>
              </v-btn>
            </p>
            <p class="text-12 mb-0">People Reached</p>
            <h3>{{ data.reach | nFormatter}}</h3>
            <p class="text-12 mb-0">
              <span class="font-weight-bold success--text">IDR {{ limitNumber(data.cpr) | nFormatter }}</span>
              CPR
              <svg @click="showCpr = !showCpr"
              xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
              <v-btn
                v-if="showCpr"
                class="text-capitalize white--text rounded-lg text-10"
                small
                color="black"
              ><span>Cost Per Reach</span>
              </v-btn>
            </p>
          </v-card>
        </div>
        <div class="col-md-2 col-sm-4 col-6">
          <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
            <v-btn
              class="mb-2"
              fab
              small
              color="success"
            >
              <i class="fas fa-history white--text text-16"></i>
            </v-btn>
            <p class="text-12 mb-0">Impressions</p>
            <h3>{{ data.impressions | nFormatter}}</h3>
            <p class="text-12 mb-0">
              <span class="font-weight-bold success--text">IDR {{ limitNumber(data.cpi) | nFormatter }}</span>
              CPI
              <svg @click="showCpi = !showCpi"
              xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
              <v-btn
                v-if="showCpi"
                class="text-capitalize white--text rounded-lg text-10"
                small
                color="black"
              ><span>Cost Per Impressions</span>
              </v-btn>
            </p>
          </v-card>
        </div>
        <div class="col-md-2 col-sm-4 col-6 pt-0">
          <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
            <v-btn
              class="mb-2"
              fab
              small
              color="info"
            >
              <i class="far fa-eye white--text text-16"></i>
            </v-btn>
            <p class="text-12 mb-0">Views</p>
            <h3>{{ data.views | nFormatter}}</h3>
            <p class="text-12 mb-0">
              <span class="font-weight-bold success--text">IDR {{ limitNumber(data.cpv) | nFormatter }}</span>
              CPV
              <svg @click="showCpv = !showCpv"
              xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
              <v-btn
                v-if="showCpv"
                class="text-capitalize white--text rounded-lg text-10"
                small
                color="black"
              ><span>Cost Per Views</span>
              </v-btn>
            </p>
          </v-card>
        </div>
        <div class="col-md-2 col-sm-4 col-6 pt-0">
          <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
            <v-btn
              class="mb-2"
              fab
              small
              color="secondary"
            >
            <i class="far fa-thumbs-up white--text text-16"></i>
            </v-btn>
            <p class="text-12 mb-0">Likes</p>
            <h3>{{ data.likes | nFormatter}}</h3>
            <p class="text-12 mb-0">
              <span class="font-weight-bold success--text">{{ limitNumber(data.comments) | nFormatter }}</span>
              Comments
            </p>
          </v-card>
        </div>
        <div class="col-md-2 col-sm-4 col-6 pt-0">
          <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
            <v-btn
              class="mb-2"
              fab
              small
              color="error"
            >
              <svg class="white--text text-22" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M11.975 22H12c3.859 0 7-3.14 7-7V9c0-3.841-3.127-6.974-6.981-7h-.06C8.119 2.022 5 5.157 5 9v6c0 3.86 3.129 7 6.975 7zM7 9a5.007 5.007 0 0 1 4.985-5C14.75 4.006 17 6.249 17 9v6c0 2.757-2.243 5-5 5h-.025C9.186 20 7 17.804 7 15V9z"/><path fill="currentColor" d="M11 6h2v6h-2z"/></svg>
            </v-btn>
            <p class="text-12 mb-0">Clicks</p>
            <h3>{{ data.clicks | nFormatter}}</h3>
            <p class="text-12 mb-0">
              <span class="font-weight-bold success--text">{{ limitNumber(data.share) | nFormatter }}</span>
              Shares
            </p>
          </v-card>
        </div>
        <div class="col-md-2 col-sm-4 col-6 pt-0">
          <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
            <v-btn
              class="mb-2"
              fab
              small
              color="accent"
            >
              <svg class="far fa-eye white--text text-20" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 0 0-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 0 1 5.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 0 1 9.288 0M15 7a3 3 0 1 1-6 0a3 3 0 0 1 6 0Zm6 3a2 2 0 1 1-4 0a2 2 0 0 1 4 0ZM7 10a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"/></svg>
            </v-btn>
            <div>
              <p class="text-12 mb-0">Followers</p>
              <h3>{{ data.followers | nFormatter}}</h3>
            </div>
            <p class="text-12 mb-0">
              <span class="font-weight-bold success--text">{{ limitNumber(data.account_engaged) | nFormatter }}</span>
              AE
              <svg @click="showAe = !showAe"
              xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
            </p>
            <v-btn
              v-if="showAe"
              class="text-capitalize white--text rounded-lg text-10"
              small
              color="black"
            ><span>Account Engaged</span>
            </v-btn>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline, mdiBullhornOutline,mdiFilterOutline } from '@mdi/js'

export default {
  props:['data'],
  setup() {
    return {
      filter: ['Last 30 days', 'Last 60 days', 'Last 90 days'],
      filterValue: '',
      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiBullhornOutline,
        mdiFilterOutline
      },
      cprSum: true,
      impressionsSum: true,
      commentSum: true,
      showCpr: false,
      showCpi: false,
      showCpv: false,
      showAe: false
    }
  },
  mounted(){
    if(!localStorage.getItem('dashboard')){
      this.filterValue = 'Last 30 days'
    } else {
      let getDashboard = JSON.parse(localStorage.getItem('dashboard'));
      this.filterValue = getDashboard.filter;
    }
  },
  methods:{
    changeSummary(summary){
      if(summary === 'cpr'){
        this.cprSum = !this.cprSum;
      } else if(summary === 'impressions'){
        this.impressionsSum = !this.impressionsSum;
      } else if(summary === 'comment'){
        this.commentSum = !this.commentSum;
      }
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    limitNumber(value){
      if(value === '' || value === null){
        return 0;
      } else {
        return Number((value).toFixed(1));
      }
    },
    filterDay(){
      // console.log(this.filterValue)
      let dashboard = {
        'filter': this.filterValue
      }
      localStorage.setItem('dashboard', JSON.stringify(dashboard));
      this.$emit('eventValue', this.filterValue);
    }
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
