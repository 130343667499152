<template>
  <div class="row">
    <div class="col-md-7">
      <p v-if="isMobile()" class="text-16 mb-2 font-weight-bold black--text">Campaign History</p>
      <v-card v-if="!isMobile()" class="elevation-1 round-16" :class="{'elevation-3 round-16': isMobile()}">
        <v-card-title v-if="!isMobile()" class="text-18 font-weight-bold">Campaign History</v-card-title>
        <v-data-table
          :headers="headers"
          :items="dataCampaign"
          :page.sync="page"
          item-key="id"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.name`]="{ item }">
            <div class="text-12">{{ item.name }}</div>
          </template>
          <template v-slot:[`item.reach`]="{ item }" >
            <v-chip color="info">
              <div v-if="item.reach > 0" class="text-12 font-weight-bold">
                {{ item.reach | nFormatter }}
              </div>
              <div v-else class="text-12 font-weight-bold">
                {{ item.reach }}
              </div>
            </v-chip>
          </template>
          <template v-slot:[`item.influencer`]="{ item }" >
            <v-chip color="warning">
              <div class="text-12 font-weight-bold">
                {{ item.influencer }}
              </div>
            </v-chip>
          </template>
          <template v-slot:[`item.date`]="{ item }" >
            <div class="text-12">
              {{ item.date }}
            </div>
          </template>
          <!-- <template v-slot:[`item.date`]="{ item }">
            <div class="text-12">
              {{ item.date | dateFormat2 }}
            </div>
          </template> -->
        </v-data-table>
      </v-card>
      <v-data-iterator v-else
        :headers="headers"
        :items="dataCampaign"
        :page.sync="page"
        item-key="id"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card class="round-16" outlined elevation="3">
                <!-- <v-card-title class="text-12 font-weight-bold">
                  <p class="black--text mb-0">{{ item.name }}</p>
                </v-card-title>

                <v-divider></v-divider> -->

                <v-list dense class="text-12">
                  <v-list-item style="min-height:15px !important">
                    <v-list-item-content class="text-10 pb-0">{{ item.date }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item style="min-height:15px !important">
                    <v-list-item-content class="font-weight-bold pb-0">{{ item.name }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item style="min-height:15px !important">
                    <v-list-item-content class="pt-1 font-weight-medium">{{ item.influencer }} Influencers</v-list-item-content>
                  </v-list-item>
                  <v-list-item style="min-height:15px !important">
                    <v-btn elevation="0" small rounded class="text-capitalize text-12 info mb-1">
                      <div v-if="item.reach > 0" class="mr-1">
                        {{ item.reach | nFormatter }}
                      </div>
                      <div v-else class="mr-1">
                        {{ item.reach }}
                      </div> People Reached
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
    <div class="col-md-5">
      <p v-if="isMobile()" class="text-16 mb-2 mt-4  font-weight-bold black--text">Leaderboard</p>
      <v-card v-if="!isMobile()" class="elevation-1 round-16">
        <v-card-title v-if="!isMobile()" class="text-18 font-weight-bold" :class="{'elevation-3 round-16': isMobile()}">Leaderboard</v-card-title>
          <v-data-table
            :headers="headersLeaderboard"
            :items="leaderboard"
            item-key="id"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:[`item.reach`]="{ item }">
              <v-chip color="info">
                <div v-if="item.reach > 0" class="text-12 font-weight-bold">
                  {{ item.reach | nFormatter }}
                </div>
                <div v-else class="text-12 font-weight-bold">
                  ---
                </div>
              </v-chip>
            </template>
            <template v-slot:[`item.impressions`]="{ item }">
              <v-chip color="success">
                <div v-if="item.impressions > 0" class="text-12 font-weight-bold">
                  {{ item.impressions | nFormatter }}
                </div>
                <div v-else class="text-12 font-weight-bold">
                  ---
                </div>
              </v-chip>
            </template>
            <template v-slot:[`item.content_influ`]="{ item }">
              <div class="text-12">{{ item.content_influ }}</div>
            </template>
            <template v-slot:[`item.username`]="{ item }">
              <div class="text-12">{{ item.username }}</div>
            </template>
          </v-data-table>
      </v-card>
      <v-data-iterator v-else
        :headers="headersLeaderboard"
        :items="leaderboard"
        :page.sync="page"
        item-key="id"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card class="round-16" outlined elevation="3">
                <v-card-title class="text-12 font-weight-bold">
                  <p class="mb-0 mr-1 text-18">#{{ item.num }}</p><p class="black--text mb-0">{{ item.username }}</p>
                </v-card-title>
                <v-divider></v-divider>
                <v-list dense class="text-12">
                  <v-list-item style="min-height:15px !important">
                    <v-list-item-content class="font-weight-bold pb-0">{{ item.content }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item style="min-height:15px !important">
                    <v-list-item-content class="pt-1 font-weight-medium">Content</v-list-item-content>
                  </v-list-item>
                  <v-list-item style="min-height:15px !important">
                    <v-list-item-content class="pt-0 pb-2 font-weight-medium">
                      <v-btn elevation="0" small rounded class="text-capitalize text-12 info mr-2 mb-0">
                        <div v-if="item.reach > 0" class="mr-1">
                          {{ item.reach | nFormatter }}
                        </div>
                        <div v-else class="mr-1">
                          {{ item.reach }}
                        </div> People Reached
                      </v-btn>
                      <v-btn elevation="0" small rounded class="text-capitalize text-12 success mb-0">
                        <div v-if="item.impressions > 0" class="mr-1">
                          {{ item.impressions | nFormatter }}
                        </div>
                        <div v-else class="mr-1">
                          {{ item.impressions }}
                        </div> Impressions
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>

                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
  </div>
</template>

<script>
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline, mdiBullhornOutline } from '@mdi/js'
export default {
  props:['dataCampaign', 'leaderboard'],
  setup() {
    return {
      page:3,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiBullhornOutline
      },
    }
  },
  mounted() {
  },
  computed: {
    headers() {
      return [
        {text: 'Campaign', value: 'name', sortable: false},
        {text: 'Influencers', value: 'influencer', sortable: false},
        // {text: 'Impressions', value: 'impressions', sortable: false},
        {text: 'Reach', value: 'reach', sortable: false},
        {text: 'Date', value: 'date', sortable: false},
      ]
    },
    headersLeaderboard() {
      return [
        {text: 'Influencer', value: 'username', sortable: false},
        {text: 'Content', value: 'content_influ', sortable: false},
        // {text: 'Followers', value: 'invoice', sortable: false},
        {text: 'Reach', value: 'reach', sortable: false, width: "1%"},
        {text: 'Impressions', value: 'impressions', sortable: false, width: "1%"},
      ]
    },
  },
  methods: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    dateFormat(value){
      return value.toString().slice(0, 10)
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters:{
    dateFormat(value){
      return value.toString().slice(0, 24)
    },
    dateFormat2(value){
      return value.toString().slice(0, 16)
    },
    dateFormat3(value){
      return value.toString().slice(3, 16)
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
