<template>
<div class="row">
  <div class="col-md-12 col-sm-6 pb-0">
    <v-card color="secondary-dark-bg" class="round-16" @click="openReport()">
      <v-card-title class="align-start pb-3">
        <p class="font-weight-bold mb-0 white--text body-1 mr-2 mt-1">Campaign Summary</p>
        <v-chip v-if="!isMobile()" color="white"><span class="text-12 black--text">{{ filterValue }}</span></v-chip>
        <v-btn v-else small rounded class="text-10 mt-1 text-capitalize">{{ filterValue }}</v-btn>
      </v-card-title>

      <v-card-subtitle class="mb-5 mt-n5">
        <!-- <span class="font-weight-semibold text--primary me-1">Total 48.5% Growth</span>
        <span>😎 this month</span> -->
      </v-card-subtitle>

      <v-card-text>
        <v-row class="white--text">
          <v-col
            cols="6"
            md="6"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiTrendingUp}}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0 font-weight-bold">
                Impressions
              </p>
              <p class="text-xl font-weight-semibold mb-0">
                {{ impressions | nFormatter }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0 font-weight-bold">
                People Reached
              </p>
              <p class="text-xl mb-0 font-weight-semibold">
                {{ reach | nFormatter }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
  <div class="col-md-12 col-sm-6">
    <v-card class="round-16" color="info-dark-bg" @click="openReport()">
      <v-card-title class="align-start pb-3">
        <span class="font-weight-bold white--text body-1  mr-2 mt-1">Campaign Report</span>
        <v-chip v-if="!isMobile()" color="white"><span class="text-12 black--text">{{ filterValue }}</span></v-chip>
        <v-btn v-else small rounded class="text-10 mt-1 text-capitalize">{{ filterValue }}</v-btn>
      </v-card-title>

      <v-card-subtitle class="mb-5 mt-n5">
        <!-- <span class="font-weight-semibold text--primary me-1">Total 48.5% Growth</span>
        <span>😎 this month</span> -->
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            md="6"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiBullhornOutline}}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0 font-weight-bold white--text">
                Campaign
              </p>
              <p class="text-xl white--text mb-0 font-weight-semibold">
                {{ campaign | nFormatter }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="6"
            md="6"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="warning"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0 font-weight-bold white--text">
                Influencers
              </p>
              <p class="text-xl mb-0 white--text font-weight-semibold">
                {{ influencers | nFormatter }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline, mdiBullhornOutline } from '@mdi/js'

export default {
  props:['impressions', 'reach', 'influencers', 'campaign'],
  setup() {
    return {
      filterValue:'',
      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiBullhornOutline
      },
    }
  },
  mounted(){
    if(!localStorage.getItem('dashboard')){
      this.filterValue = 'Last 30 days'
    } else {
      let getDashboard = JSON.parse(localStorage.getItem('dashboard'));
      this.filterValue = getDashboard.filter;
    }
  },
  methods:{
    openReport(){
      this.$router.push({name:'campaigns'}); 
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
